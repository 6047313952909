import React, { Component } from 'react';

import ComingSoonPage from './pages/ComingSoonPage';

export default class Router extends Component {

  render() {
    return (
      <ComingSoonPage />
    )
  }
}